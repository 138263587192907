// js для свайпера, подключается блоками
// подробнее тут <https://swiperjs.com/get-started>
import SwiperCore, {Swiper, Navigation, Pagination, Thumbs, Autoplay} from "swiper/core";
SwiperCore.use([Swiper, Navigation, Pagination, Thumbs, Autoplay]);

// стили для свайпера
// брать тут <https://swiperjs.com/swiper-api#styles>, только для нужных компонентов
import "swiper/swiper.min.css";
import "swiper/components/thumbs/thumbs.min.css";
// import "swiper/components/navigation/navigation.min.css";

window.addEventListener("DOMContentLoaded", () => {
    // ########################################################
    const main = document.querySelector('[data-swiper-main]')
    if (main) {
        new Swiper(main, {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            slidesPerColumnFill: 'row',

            // Мобайл-first подход (min-width: ...)
            // breakpoints: {
            // },
            navigation: {
                prevEl: main.querySelector('.swiper-button-prev'),
                nextEl: main.querySelector('.swiper-button-next'),
            },
            // // ========================================================
            pagination: {
                type: 'bullets',
                el: main.querySelector('.swiper-pagination')
            },
            // // ========================================================
            loop: true,
            // Скорость переключения слайдов
            speed: 1000,
            // Автопрокрутка
            autoplay: {
                // Задержка
                delay: 10000,
                // Приостановить при наведении мышью
                pauseOnMouseEnter: true,
                // 	// Закончить на последнем слайде
                // 	stopOnLastSlide: true,
                // откючить после юзера
                disableOnInteraction: false,
            },
            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }
    // ########################################################
    const products = document.querySelector('[data-swiper-products]')
    if (products) {
        new Swiper(products, {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            slidesPerColumnFill: 'row',
            spaceBetween: 20,

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    slidesPerColumn: 1,
                },
                600: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    slidesPerColumn: 1,
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    slidesPerColumn: 5,
                },
                1280: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    slidesPerColumn: 5,
                },
                // 1920: {
                //     slidesPerView: 5,
                //     slidesPerGroup: 5,
                //     slidesPerColumn: 5,
                // }
            },
            navigation: {
                prevEl: products.querySelector('.swiper-button-prev'),
                nextEl: products.querySelector('.swiper-button-next'),
            },
            // // ========================================================
            pagination: {
                type: 'bullets',
                el: products.querySelector('.swiper-pagination')
            },
            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }
    // ########################################################
    const advantages = document.querySelector('[data-swiper-advantages]')
    if (advantages) {
        new Swiper(advantages, {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            spaceBetween: 20,

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 5,
                },
            },
            navigation: {
                prevEl: advantages.querySelector('.swiper-button-prev'),
                nextEl: advantages.querySelector('.swiper-button-next'),
            },
            // // ========================================================
            pagination: {
                type: 'bullets',
                el: advantages.querySelector('.swiper-pagination')
            },
            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }
    // ########################################################
    const partners = document.querySelector('[data-swiper-partners]')
    if (partners) {
        new Swiper(partners, {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            spaceBetween: 30,

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                1280: {
                    slidesPerView: 3,
                }
            },
            navigation: {
                prevEl: '.partners__prev',
                nextEl: '.partners__next',
            },
            // // ========================================================
            pagination: {
                type: 'bullets',
                el: partners.querySelector('.swiper-pagination')
            },
            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }
    const goods = document.querySelector('[data-swiper-goods]')
    if (goods) {
        new Swiper(goods, {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            spaceBetween: 20,
            direction: 'vertical',

            // Миниатюры
            thumbs: {
                autoScrollOffset: 1,
                swiper: {
                    el: '[data-swiper-goods-thumbs]',
                    spaceBetween: 20,
                    slidesPerView: 2,
                    direction: 'vertical',

                    navigation: {
                        nextEl: '.goods__next',
                    },
                },
            },
            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,
        });
    }
});


