import { Menu } from "./default/_Menu";
import { Tab } from './default/_Tab'

window.addEventListener('DOMContentLoaded', () => {
    initMenu()

    new Tab('[data-tab-btn]', '[data-tab-body]', {
        activeNumber: 0,
        useClass: 'selected',
        afterInit: (inst) => {
            select('[data-tab-select]', '[data-tab-select-container]')
        }
    })
})

function initMenu() {
    const burger = document.querySelector('[data-burger]')
    const menu = document.querySelector('[data-menu]')

    if(burger && menu) {
        new Menu(burger, menu)
    }
}


function select(selectSelector, containerSelectors) {
    const select = document.querySelectorAll(selectSelector)
    const container = document.querySelectorAll(containerSelectors)

    if(select.length > 0 && container.length == select.length) {
        select.forEach((el, key) => {
            init(el, container[key])
        })
    }
    function init(select, container) {
        const items = container.children
        // установка видимого текста
        replaceText(select, items[0])

        select.addEventListener('click', () => {
            action('toggle')
        })

        for(const item of items) {
            item.addEventListener('click', () => {
                replaceText(select, item)
                action('remove')
            })
        }

        function replaceText(elem, donor) {
            elem.textContent = donor.textContent
        }
        // remove, add, toggle
        function action(action) {
            select.classList[action]('active')
            container.classList[action]('active')
        }
    }
}

