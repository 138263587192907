
// #################################################
//  Use btn/body className and number default active tab
// example: new Tab('.btn', '.body', {
//      activeNumber: 0,
// })
// #################################################

export class Tab {
    /**
     * @namespace Tab
     * @param {String} btnSelector - any "btn" selector
     * @param {String} bodySelector - any "body" selector
     */
    constructor(btnSelector, bodySelector, options = {
        /**
         * @property {Element|document} Define parent selector to your "tab"
         */
        parentSelector: document,
        /**
         * @property {number|null} Define number an active tab by default
         */
        activeNumber: null,
        /**
         * @property {boolean|string} Define added class to your "body & btn" selectors
         */
        useClass: false,
        /**
         * @property {boolean} Define, will be "tab" toggle on click
         */
        selfClose: false,
        /**
         * @property {boolean|function} Define function after click
         */
        afterClick: false,
        /**
         * @property {boolean|function} Define function after init
         * @returns {Object} Instance class
         */
        afterInit: false
    }) {
        this.btnSelector = btnSelector
        this.bodySelector = bodySelector
        this.parentSelector = options.parentSelector
        this.activeNumber = options.activeNumber
        this.useClass = options.useClass
        this.selfClose = options.selfClose
        this.afterClick = options.afterClick
        this.afterInit = function(inst) {
            if(options.afterInit) {
                options.afterInit(inst)
            }
        }
        this.init()
    }

    init() {
        return new Promise(resolve => {
            if(this.getParent()) {
                // проверяет  существование элементов, а также равное ли их количество
                if(this.getBtns().length > 0 && this.getBtns().length == this.getBodys().length) {
                    this.getActiveNumber()
                    this.getBtns().forEach((btn, key) => this.main(btn, this.getBodys()[key]))
                } else {
                    return
                }
            }
            resolve()
        }).then(() => {
            this.afterInit(this)
        })

    }

    getBtns() {
        const allBtns = this.getParent().querySelectorAll(this.btnSelector) || false
        return allBtns
    }

    getBodys() {
        const allBody = this.getParent().querySelectorAll(this.bodySelector) || false
        return allBody
    }

    getParent() {
        if(this.parentSelector) {
            return document.querySelector(this.parentSelector) || false
        } else {
            return document
        }
    }

    getActiveNumber() {
        if(!isNaN(parseInt(this.activeNumber))) {
            // проверяет является ли заданное число больше длинны элементов (отсчет элементов идет с нуля, а длинны с единицы)
            if(this.activeNumber == this.getBtns().length) {
                throw new Error('Active number can\'t be biggest than btn length')
            } else {
                this.action('add', this.getBtns()[this.activeNumber],this.getBodys()[this.activeNumber])
            }
        } else {
            return false
        }
    }

    main(btn, body) {
        btn.addEventListener('click', (event) => {
            event.preventDefault();
            this.onClick(btn, body)
            if(this.afterClick) {
                this.afterClick(this)
            }
        })
    }

    onClick(btn, body) {
        if(this.selfClose) {
            this.toggle(btn, body)
        } else {
            this.getBtns().forEach((el, key) => {
                this.action('remove', el, this.getBodys()[key])
            })
            this.action('add', btn, body)
        }
    }
    // TODO add toggle
    // add, remove
    action(action, btn, body) {
        if(this.useClass) {
            btn.classList[action](this.useClass)
            body.classList[action](this.useClass)
        } else {
            btn.classList[action]('show')
            body.style.display = 'block'
        }
    }

    toggle(btn, body) {
        if(this.useClass) {
            btn.classList.toggle(this.useClass)
            body.classList.toggle(this.useClass)
        } else {
            btn.classList.toggle('show')
            if(body.style.display == 'none') {
                body.style.display = 'block'
            } else {
                body.style.display = 'none'
            }
        }
    }
    // Методы
    // возвращает индекс активной вкладки
    getActiveIndex() {
        for(let i = 0; i < this.getBtns().length; i++) {
            if(this.getBtns()[i].classList.contains(this.useClass)) {
                return i
            }
        }
    }

    getThis() {
        return this
    }
}
