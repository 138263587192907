require('../scss/app.scss');
// main
require('./_script');
require('./default/_swiper');
require('./default/_maps');


// Зависимости
// TODO Убрать лишнее
window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
