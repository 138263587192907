export class Menu {
    constructor(burgerSelector, containerSelector) {
        this.burger = this.getElement(burgerSelector)
        this.menu = this.getElement(containerSelector)
        this.init()
    }

    init() {
        this.click()
    }

    getElement(selector) {
        if(typeof selector === 'object') {
            return selector
        } else {
            return document.querySelector(selector)
        }
    }

    click() {
        const btns = this.menu.querySelectorAll('[data-btn]')

        window.addEventListener('click', (e) => {
            if(this.burger.contains(e.target)) {
                this.activeAction(this.burger, 'toggle')
                this.activeAction(this.menu, 'toggle')
                // Подменю
            } else if([...btns].includes(e.target)) {
                [...btns].forEach(el => {
                    this.activeAction(el.nextElementSibling, 'remove')
                })
                this.activeAction(e.target.nextElementSibling, 'toggle')
            } else {
                this.activeAction(this.burger, 'remove')
                this.activeAction(this.menu, 'remove')
                btns.forEach(element => this.activeAction(element.nextElementSibling, 'remove'));
            }
        })
    }

    activeAction(elem, action) {
        return elem.classList[action]('active')
    }
}
