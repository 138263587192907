// Функция ymaps.ready() будет вызвана, когда
// загрузятся все компоненты API, а также когда будет готово DOM-дерево.
window.addEventListener('load', () => {
    if(typeof ymaps !== 'undefined') {
        ymaps.ready(init);
    }
})

function init() {
    const contactsMap = document.getElementById('contacts-map')

    if(contactsMap) {
        // Создание карты.
        const mapContacts = new ymaps.Map(contactsMap, {
            // Координаты центра карты (широта, долгота). Только для начальной инициализации, затем заменятся метками и их координатами
            center: getMapCenter(marks),
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 15,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],

        })
        // Увеличение карты при скроле
        mapContacts.behaviors.disable('scrollZoom');
        // Добавление меток (массив обьектов "marks" передается в шаблоне)
        if(typeof marks != 'undefined') {
            createMarks(marks, mapContacts)
        }
    }
}

// создание меток из массива
function createMarks(arr, map) {
    if(arr.length > 0) {
        arr.forEach(el => {
            map.geoObjects.add(mark(el))
        });
    } else {
        throw new Error('Marks array empty');
    }
}
// обьект создания метки
function mark(el) {
    return new ymaps.Placemark(el.coord, {
        hintContent: el.name || '',
        balloonContent: el.text || '',
    }, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: el.image || 'static/images/base/mark.png',
        // Размеры метки.
        iconImageSize: el.size || [30, 42],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        iconImageOffset: [-5, -38]
    })
}

// если нужно определить центр карты по меткам
function getMapCenter(arr) {
    if(typeof arr !== 'undefined') {
        let x = 0, y = 0;
        if(arr.length > 0) {
            arr.forEach((el, key) => {
                x += el.coord[0]
                y += el.coord[1]
            })

            return [getCoord(x), getCoord(y)];
        } else {
            throw new Error('Marks array empty');
        }
    } else {
        // Некие базовые координаты
        return [58.611012, 49.597999]
    }

    function getCoord(coord) {
        return coord = (coord / arr.length).toFixed(7)
    }
}
